import {languageCodes, localeBases} from "$lib/locale/locale.js";
import {getUrlPaths} from "$lib/urlPaths.js";

/** This matcher is used to match all urls that are exactly one folder deep from the root. Not more and not less.
 * The challenge is that some links that should be redirected to django pages will be caught by the landing page matcher
 * otherwise, because when a client clicks the link, svelte don't actually make a requests to the server, and therefore
 * bypasses the nginx and load balancer that would otherwise redirect the request to django/WordPress.
 * This is not a perfect solution, but since we will add any page we would link to from svelte-kit in the
 * urlPaths.js file, this should be a good enough solution.
 * Once all pages have been migrated to svelte-kit, this matcher can be removed.
 */

const urlPaths = getUrlPaths(languageCodes.danish)

const exceptions = [];

// add all the locale bases to the exceptions. Otherwise paths like /en/ will be unavailable
for (const [key, value] of Object.entries(localeBases)) {
    if (value !== "") {
        exceptions.push(value.replace(/^\/+|\/+$/g, ''))
    }
}

function findAllExceptions(urlDict) {
    // Recursively find all urls that are exactly one folder deep from the root. Not more and not less.

    for (const [key, value] of Object.entries(urlDict)) {
        if (typeof value === "object") {
            // Look for urls in nested objects
            findAllExceptions(value)
        } else if (typeof value === "string") {
            const stripped = value.replace(/^\/+|\/+$/g, '');
            if (stripped.split("/").length === 1 && stripped !== "") {
                exceptions.push(stripped)
            }
        } else {
            // Skip functions etc
        }
    }
}


findAllExceptions(urlPaths)

const regex = new RegExp(`^(?!` + exceptions.join("|") + `).*?$`)

export function match(param) {
    return regex.test(param);
}
